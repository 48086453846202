import { Component } from '@angular/core';
import { OnInit } from  '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'trevor-site';
  config: any;
  fullpage_api: any;

  constructor() {
    // for more details on config options please visit fullPage.js docs
    this.config = {

      // fullpage options
      licenseKey: "A1EDB94C-11364A0D-A5481FC6-AFE1F79F",
      anchors: ["home", "work", "music", "alpha"],
      sectionsColor: ["#cccccc", "#ffffff", "#ff6100", "#ffffff"],
    };
  }

  ngOnInit() {
  }

  getRef(fullPageRef: any) {
    this.fullpage_api = fullPageRef;
  }

}
