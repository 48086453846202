import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {NameComponent} from './components/name/name.component';
import {WorkComponent} from './components/work/work.component';
import {MusicComponent} from './components/music/music.component';
import {AlphaComponent} from './components/alpha/alpha.component';

const routes: Routes = [
  {path: '', component: NameComponent},
  {path: 'work', component: WorkComponent},
  {path: 'music', component: MusicComponent},
  {path: 'alpha', component: AlphaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
