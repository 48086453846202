import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class D3VisualsService {

  _d3GcsUrl = 'https://us-central1-data-268406.cloudfunctions.net/trevor-site-d3';

  constructor(private httpClient: HttpClient) { }

  getDataSet(request: Object) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': '*',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.httpClient.post(this._d3GcsUrl, request, httpOptions);
  }

}
