<div class="grid-container">
  <div class="grid-child purple">
    <app-visualization></app-visualization>
  </div>

  <div class="grid-child green">
    <h1>music</h1>
    <h2>fake book</h2>
    <p>Music is passion that has been a constant in my life.</p>
    <p>
      <i class="em em-guitar" aria-role="presentation" aria-label="GUITAR"></i>
      <i
        class="em em-musical_keyboard"
        aria-role="presentation"
        aria-label="MUSICAL KEYBOARD"
      ></i>
      <i
        class="em em-saxophone"
        aria-role="presentation"
        aria-label="SAXOPHONE"
      ></i>
      <i
        class="em em-musical_note"
        aria-role="presentation"
        aria-label="MUSICAL NOTE"
      ></i>
      <i
        class="em em-computer"
        aria-role="presentation"
        aria-label="PERSONAL COMPUTER"
      ></i>
    </p>
    <p>
      <i
        class="em em-arrow_left"
        aria-role="presentation"
        aria-label="LEFTWARDS BLACK ARROW"
      ></i>
      I'm building a fake book
    </p>
  </div>
</div>
