<div class='center-container'>
    <div class='left' >
        <img *ngFor="let tile of this.tiles" src={{tile.url}} />
     </div>
    <div class='right'>
        <div class='description-container'>
            <p>I <i class="em em-heart" aria-role="presentation" aria-label="HEART"></i>
                working with great projects. Check out what I've done with these here</p>
            <a href="https://www.linkedin.com/in/the-trevor-harris/">
                <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png">
            </a>
        </div>
    </div>
</div>
