<div class='center-container'>
    <a class='wrench' href="https://www.flaticon.com/authors/kiranshastry">
        <img class='wrench' src="assets/spanner.svg">
    </a>
    <div>
        <h1>Coming Soon!</h1>
        <p>Stay tuned for logins to alpha projects I work on and links to repos I've open sourced</p>
    </div>
    <a class='github' href="https://github.com/trevorharris05">
        <img class='github' src="https://github.githubassets.com/images/modules/logos_page/Octocat.png">
    </a>
</div>