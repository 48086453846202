import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../app-state';


@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements OnInit {

  data$ = this.store.pipe(select(state => state));

  constructor(private store: Store<AppState>) { }

  chart(root_data: any) {

    // source helper functions

    function autoBox() {
      let container = d3.select("svg#data-vis").node();
      //@ts-ignore
      const {x, y, width, height} = container.getBBox();
      // console.log([x, y, width, height]);
      return [x, y, width, height];
    }

    let width = 850
    let radius = width / 2

    let tree = d3.cluster().size([2 * Math.PI, radius - 100])

    // chart
    const root = tree(d3.hierarchy(root_data)
      .sort((a, b) => d3.ascending(a.data.name, b.data.name)));

    const svg = d3.select("svg#data-vis");

    svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
        //@ts-ignore
      .attr("d", d3.linkRadial().angle(d => d.x).radius(d => d.y)
       );

    svg.append("g")
      .selectAll("circle")
      .data(root.descendants())
      .join("circle")
      .attr("transform", d => `
          rotate(${d.x * 180 / Math.PI - 90})
          translate(${d.y}, 0)
        `)
      .attr("fill", d => d.children ? "#555" : "#999")
      .attr("r", 2.5);

    svg.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 4)
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .attr("transform", d => `
          rotate(${d.x * 180 / Math.PI - 90}) 
          translate(${d.y},0) 
          rotate(${d.x >= Math.PI ? 180 : 0})
        `)
      .attr("dy", "0.31em")
      .attr("x", d => d.x < Math.PI === !d.children ? 6 : -6)
      .attr("text-anchor", d => d.x < Math.PI === !d.children ? "start" : "end")
      //@ts-ignore
      .text(d => d.data.name)
      .clone(true).lower()
      .attr("stroke", "white");

    //@ts-ignore
    return svg.attr("viewBox", autoBox).node();

  }

  ngOnInit(): void {

    this.data$.subscribe(musicH => {
      // Debug:
      // console.log('on subscribe of data in visualization component')
      // console.log(musicH);
      this.chart(musicH.musicHierarchy);
    })

  }

}
