import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app-state';
import { getD3DataSetRequest } from '../../actions/visualization.actions';


@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss']
})
export class NameComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {

    //let request = {name: 'flare.json'};
    let request = {name: 'fake-book-tree.json'};
    this.store.dispatch(getD3DataSetRequest(request));
  }

}
