import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';

import { D3VisualsService } from '../services/d3-visuals.service'

import { getD3DataSetRequest, getD3DataSetDone } from '../actions/visualization.actions';

@Injectable()
export class VisualizationEffects {

  constructor(
    private visSvc: D3VisualsService,
    private actions$: Actions,
  ) {}

  getD3Data$ = createEffect(() => this.actions$.pipe(
    ofType(getD3DataSetRequest),
    switchMap((action) => {
      return this.visSvc.getDataSet(action).pipe(
        map(data => getD3DataSetDone({ data })),
      );
    }),
  ));

}
