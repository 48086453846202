import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NameComponent } from './components/name/name.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatGridListModule } from '@angular/material/grid-list';
import { WorkComponent } from './components/work/work.component';
import { MusicComponent } from './components/music/music.component';
import { AlphaComponent } from './components/alpha/alpha.component';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { VisualizationComponent } from './components/music/components/visualization/visualization.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { getD3DataSetDoneReducer } from './reducers/visualization.reducers';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { VisualizationEffects } from './effects/visualization.effects'

import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [
    AppComponent,
    NameComponent,
    WorkComponent,
    MusicComponent,
    AlphaComponent,
    VisualizationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    AngularFullpageModule,
    StoreModule.forRoot({
      musicHierarchy: getD3DataSetDoneReducer
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([ VisualizationEffects ]),
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
