import { createReducer, on } from '@ngrx/store';

import { getD3DataSetRequest, getD3DataSetDone } from '../actions/visualization.actions';

export const getD3DataSetReducer = createReducer<Object>({},
  on(getD3DataSetRequest, function (question: any, action: any) {
    // console.log("Debug getD3DataSet Reducer");
    // console.log(question); //this is likely state
    // console.log(action);
    return action;
  })
);

export const getD3DataSetDoneReducer = createReducer<Object>({},
  on(getD3DataSetDone, (question: any, action: any) => {
    // console.log("Debug getD3DataSetDoneReducer");
    // console.log(question); //this is likely state
    // console.log(action);
    return action.data;
  })
);